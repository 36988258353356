@tailwind base;

@layer base {
  html {
    @apply scroll-smooth text-gray-900;
  }
  input,
  textarea {
    @apply placeholder:text-gray-400;
  }
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-none {
    font-size: 0;
  }

  .home-page-hero-gradient {
    background-color: transparent;
    background-image: radial-gradient(
        at 0% 0%,
        rgb(184, 205, 249) 0,
        rgba(255, 255, 255, 0) 60%
      ),
      radial-gradient(
        at 100% 0%,
        rgb(219, 217, 239) 0,
        rgba(255, 255, 255, 0) 45%
      );
  }

  .common-page-section-gradient {
    background-color: white;
    background-image: radial-gradient(
        at 0% 0%,
        rgb(167, 193, 222) 0,
        rgba(255, 255, 255, 0) 80%
      ),
      radial-gradient(
        at 100% 40%,
        rgb(243, 220, 228) 0,
        rgba(255, 255, 255, 0) 75%
      ),
      radial-gradient(
        at 45% 70%,
        rgb(207, 206, 231) 0,
        rgba(255, 255, 255, 0) 40%
      );
  }

  .contact-page-hero-gradient {
    background: linear-gradient(
      58deg,
      rgb(255, 236, 240) 0%,
      rgb(217, 222, 241) 59%,
      rgb(205, 210, 232) 100%
    );
  }

  .partners-page-hero-gradient {
    background: transparent
      linear-gradient(
        60deg,
        rgb(253, 232, 236) 0%,
        rgb(221, 223, 238) 51%,
        rgb(215, 210, 230) 100%
      );
  }

  .social-impact-gradient {
    background-color: white;
    background-image: radial-gradient(
        at 0% 0%,
        white 0,
        rgba(255, 255, 255, 0) 50%
      ),
      radial-gradient(
        at 0% 100%,
        rgb(243, 220, 228) 0,
        rgba(255, 255, 255, 0) 40%
      ),
      radial-gradient(
        at 0% 0%,
        rgb(167, 193, 222) 0,
        rgba(255, 255, 255, 0) 80%
      ),
      radial-gradient(
        at 100% 40%,
        rgb(243, 220, 228) 0,
        rgba(255, 255, 255, 0) 75%
      ),
      radial-gradient(
        at 45% 70%,
        rgb(207, 206, 231) 0,
        rgba(255, 255, 255, 0) 40%
      );
  }

  .insurance-page-hero-gradient {
    background: radial-gradient(
        67.65% 48.92% at 72.73% 14.25%,
        rgba(215, 216, 234, 0.5) 0%,
        rgba(215, 213, 233, 0) 100%
      ),
      radial-gradient(
        50.27% 50.27% at 14.77% 32.26%,
        rgba(254, 235, 239, 0.5) 0%,
        rgba(252, 236, 239, 0) 100%,
        rgba(252, 236, 239, 0) 100%
      ),
      linear-gradient(
        226.55deg,
        rgba(222, 221, 238, 0.5) 8.4%,
        rgba(248, 248, 249, 0) 95.8%
      ),
      linear-gradient(
        208.11deg,
        rgba(194, 210, 247, 0.5) 8.63%,
        rgba(202, 211, 232, 0) 87.1%
      ),
      linear-gradient(190.8deg, #dcdbef 5.83%, #dadfec 61.47%, #fcfcfc 91.99%);
  }
  .careers-page-hero-gradient {
    background:
      radial-gradient(
          54.14% 46.77% at 43.41% 25%,
          rgba(189, 208, 249, 0.5) 0%,
          rgba(189, 208, 249, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          73.32% 76.61% at 73.18% 23.39%,
          rgba(201, 214, 247, 0.5) 0%,
          rgba(230, 229, 244, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          38.67% 35.22% at 42.05% 47.04%,
          rgba(215, 215, 232, 0.5) 0%,
          rgba(223, 216, 234, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      linear-gradient(
        270deg,
        rgba(243, 234, 242, 0.5) 2.5%,
        rgba(189, 208, 249, 0) 100%
      ),
      linear-gradient(
        90deg,
        rgba(206, 219, 237, 0.5) 0%,
        rgba(234, 232, 239, 0.25) 102.95%
      ),
      linear-gradient(
        180deg,
        rgba(254, 235, 239, 0.5) 0%,
        rgba(254, 235, 239, 0) 100%
      ),
      linear-gradient(214.97deg, #bdd0f9 0%, #e5e6f3 38.47%, #fcfcfc 79.42%);
  }
}

/*
 * Algolia InstantSearch
 */
.ais-HierarchicalMenu-count,
.ais-RefinementList-count {
  @apply rounded bg-gray-200 px-0.5 font-mono text-xs;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  @apply hidden;
}

.ais-RefinementList-label,
.ais-NumericMenu-label,
.ais-ToggleRefinement-label {
  @apply flex items-center gap-1;
}

/* Panel */
.ais-Panel {
  @apply bg-gray-100 p-4 text-sm;
}
.ais-Panel-header {
  @apply mb-2 font-bold;
}

/* Stats */
.ais-Stats {
  @apply text-sm;
}

/* HitsPerPage */
.ais-HitsPerPage-select {
  @apply rounded-md border bg-gray-100 p-1 pl-2 pr-8 text-sm;
}

/* Pagination */
.ais-Pagination {
  @apply flex items-center justify-center space-x-1 py-4 text-sm;
}
.ais-Pagination-list {
  @apply flex items-center space-x-1;
}
.ais-Pagination-link {
  @apply hover:border-primary-700 focus:ring-primary-700 flex h-8 w-8 items-center justify-center rounded border shadow-sm focus:outline-none focus:ring-2 focus:ring-opacity-50;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  @apply border-primary-700 ring-primary-700 ring-2 ring-opacity-50;
}
.ais-Pagination-item--disabled .ais-Pagination-link {
  @apply border-none bg-gray-100 shadow-none;
}

/* HierarchicalMenu */
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  @apply pl-2;
}
.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link {
  @apply text-primary-700;
}
.ais-HierarchicalMenu-link {
  @apply hover:text-primary-700 flex items-center gap-2;
}
.ais-HierarchicalMenu-label {
  @apply truncate;
}

/* RefinementList */
.ais-RefinementList {
  @apply flex flex-col gap-1;
}
.ais-SearchBox-form {
  @apply relative;
}
.ais-SearchBox-input {
  @apply w-full rounded border p-1 shadow-inner;
}

/* ClearRefinements */
.ais-ClearRefinements-button {
  @apply text-primary-700 hover:border-primary-700 rounded border p-2 text-sm leading-none shadow-sm;
}
.ais-ClearRefinements-button--disabled {
  @apply border-transparent bg-gray-100 text-gray-900 shadow-none hover:border-transparent;
}

progress {
  @apply h-2 rounded-lg border-0 bg-gray-200;
}
progress::-webkit-progress-bar {
  @apply h-2 rounded-lg border-0 bg-gray-200;
}
progress::-webkit-progress-value {
  @apply bg-success-700 relative h-2 rounded-lg border-0;
}
progress::-moz-progress-bar {
  @apply h-2 rounded-lg border-0 bg-gray-200;
}

/* keen slider on about us page */
.navigation-wrapper {
  @apply relative;
}
.dots {
  @apply flex justify-start;
}
.dot {
  @apply mx-2.5 h-2 w-2 cursor-pointer rounded-full bg-gray-300 p-1;
}
.dot:focus {
  @apply outline-none;
}
.dot.active {
  @apply bg-primary-700 ring-primary-100 ring-4;
}
